import React, { useEffect, useRef, useState } from 'react';
import style from './roundedTextInputStyle.module.scss';

interface IProps {
    value: number | null;
    placeholder?: string | null;
    onChange: (value: number | null) => void;
    color?: "light" | "dark";
    style?: React.CSSProperties;
    minValue?: number;
    maxValue?: number;
    autoFocus?: boolean;
}

export function RoundedNumberInput(props: IProps) {
    const color = props.color ?? "light";
    const [internalValue, setInternalValue] = useState<number | null>(props.value ?? null);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.value !== null) {
            setInternalValue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if (props.autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [props.autoFocus]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        let numberValue = inputValue == "" ? null : parseFloat(inputValue);

        if (props.minValue !== undefined && numberValue != null && numberValue < props.minValue) {
            numberValue = props.minValue;
        }
        if (props.maxValue !== undefined && numberValue != null && numberValue > props.maxValue) return;

        setInternalValue(numberValue);
        props.onChange(numberValue);
    };

    return (
        <input
            type="number"
            ref={inputRef}
            inputMode="decimal"
            pattern="[0-9]*"
            style={props.style}
            className={`${style.input} ${color == "light" ? style.light : style.dark}`}
            value={internalValue ?? ""}
            onChange={handleChange}
            placeholder={props.placeholder ?? undefined}
        />
    )
}
