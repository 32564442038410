import WebAppUser from "@twa-dev/sdk";
import { PropsWithChildren, useMemo, useState } from 'react';
import GameContext from '../Context/GameContext';

export enum Currency {
    Pix = 1,
    Usdt = 2,
    Ton = 3,
    Dao = 4
}

export interface GameState {
    score: number,
    coinsPerClick: number,
    coinsPerSecond: number,
    playerImprovements: any,
    energy: number,
    maxEnergy: number,
    totalEarn: number,
    userId: number,
    level: number | null,
    coinId: number | null,
    skinId: number | null,
    coinImageId: number | null,
    skinImageId: number | null,
    skinEarningBoost: number,
    skinPerTapBoost: number,
    energyBarBoost: number,
    family_id: number | null,
    token: string | null,
    event_clicks_counter: number,
    default_coins_per_second: number,
    default_coins_per_tap: number,
    default_max_energy: number,
    eventTimeEndSecond: number,
    energyPerSecond: number,
    coinShadowColor?: string,
    dao_balance: number,
    selectedCurrency: Currency,
    balance_usdt: number,
    firstName: string,
    lastName: string,
    username: string,
    coinsNeedForNewLevel: number,
    balance_usdt_staking: number
}

export default function GameProvider(props: PropsWithChildren) {
    // console.log("Game provider loaded");
    const isRunningInTelegram = () => {
        return typeof WebAppUser.platform === 'undefined' ? false : true;
    };

    // Function to check if the app is running on localhost
    const isLocalhost = () => {
        return window.location.hostname === 'localhost' && window.location.port === '3000';
    };

    const [gameState, setGameState] = useState<GameState>({
        score: null!,
        coinsPerClick: 0,
        coinsPerSecond: 0,
        playerImprovements: {},
        energy: 0,
        maxEnergy: 0,
        totalEarn: 0,
        userId: (!isRunningInTelegram() && isLocalhost()) ? 875591451 : WebAppUser.initDataUnsafe.user?.id!,
        level: null,
        coinId: null,
        skinId: null,
        coinImageId: null,
        skinImageId: null,
        skinEarningBoost: 0,
        skinPerTapBoost: 0,
        energyBarBoost: 0,
        family_id: null,
        token: null,
        event_clicks_counter: 0,
        default_coins_per_second: 0,
        default_coins_per_tap: 0,
        default_max_energy: 0,
        eventTimeEndSecond: 0,
        energyPerSecond: 0,
        dao_balance: 0,
        selectedCurrency: Currency.Pix,
        balance_usdt: 0,
        firstName: "",
        lastName: "",
        username: "",
        coinsNeedForNewLevel: 0,
        balance_usdt_staking: 0
    });

    // 939941303
    // useEffect(() => {
    //     console.log("=========================")
    //     console.log(isRunningInTelegram())
    //     console.log(isLocalhost())
    //     console.log(isLocalhost())
    //     console.log("=========================")
    // }, []);

    // Функция для обновления состояния
    // const updateGame = (updates) => {
    //     setGameState((prevState) => ({ ...prevState, ...updates }));
    // };

    function updateGame(updates: any) {
        setGameState(prevState => {
            if (typeof updates === 'function') {
                return { ...prevState, ...updates(prevState) };
            }
            return { ...prevState, ...updates };
        });
    };

    const value = useMemo(() => ({
        ...gameState,
        updateGame,
    }), [gameState]);

    return (
        <GameContext.Provider value={value} >
            {props.children}
        </GameContext.Provider>
    );
};
