import { useContext, useEffect } from 'react'
import style from './layoutStyle.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import GameContext from '../../../Context/GameContext';
import BottomMenu from '../../BottomMenu';
import DraggableButton from '../draggableButton/draggableButton';

export function PredictLayout() {
    const { userId } = useContext(GameContext);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/predict' || location.pathname === '/predict/') navigate(`/predict/predictions`);
    }, [])

    return (
        <div className={style.app} style={{ paddingTop: 70 }}>
            <Outlet />
            <div className={style.bottomMenu}>
                <BottomMenu />
            </div>
            {
                location.pathname != `/tap/${userId}` &&
                <DraggableButton onClick={() => navigate(`/tap/${userId}`)} />
            }
        </div>
    )
}
