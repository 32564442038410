import React, { useContext, useEffect, useState } from 'react'
import style from './profileStyle.module.scss'
import smallArrowDown from '../../../Resources/Predict/icons/smallarrordown.svg'
import LicenceNftIcon from '../../../Resources/Predict/images/nft/Licence.jpg'
import LandsNftIcon from '../../../Resources/Predict/images/nft/Lands.jpeg'
import HousesNftIcon from '../../../Resources/Predict/images/nft/Houses.jpg'
import CoinIcon from '../../../Resources/Predict/icons/coin.svg'
import HousesDiamondNftIcon from '../../../Resources/Predict/images/nft/HousesDiamond.jpg'
import { useNavigate } from 'react-router-dom'
import { PredictProvider } from '../../../Domain/Predict/predictProdiver'
import GameContext from '../../../Context/GameContext'
import PLoader from '../../../Components/Predict/loader/Loader'
import { NftBlock } from '../../../Domain/Predict/nftBlock'
import { disableVerticalSwipes, enableVerticalSwipes } from "@telegram-apps/sdk-react";
import { useTranslation } from 'react-i18next'

interface TableRow {
    id: number;
    type: string;
    price: string;
    pricePercent: string;
    usdtEarned: string;
    usdtEarnedPercent: string;
    pixEarned: string;
    pixEarnedPercent: string;
    image: string;
}

export interface nft {
    name: string,
    number: string,
    price: number,
    code: number,
    photo: string
}

export function ProfileNftsBlock() {
    const { token } = useContext(GameContext);

    const [nfts, setNfts] = useState<NftBlock[]>([]);
    const [profileNftFilterModalIsOpen, setProfileNftFilterModalIsOpen] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        loadNfts()
    }, [])

    useEffect(() => {
        if (disableVerticalSwipes.isAvailable()) {
            disableVerticalSwipes();
        }

        return () => {
            if (enableVerticalSwipes.isAvailable()) {
                enableVerticalSwipes();
            }
        }
    }, [])

    async function loadNfts() {
        setIsLoaded(false);

        const nfts = await PredictProvider.getNfts(token!);
        setNfts(nfts)

        setIsLoaded(true);
    }

    function closeProfileNftFilterModal() {
        setProfileNftFilterModalIsOpen(prev => !prev);
    }

    const toggleRow = (name: string) => {
        setExpandedRows(prev =>
            prev.includes(name) ? prev.filter(rowId => rowId !== name) : [...prev, name]
        );
    };

    if (!isLoaded) return <PLoader />

    return (
        <div className={style.profileNftsBlock}>
            {/* <div className={style.profileNftsBlock_searchInput}>
                <img src={searchNftIcon} alt="SearchIcon" />
                <input type='text' placeholder='Search' />
                <img src={filterIcon} alt="filterIcon" onClick={closeProfileNftFilterModal} />
            </div> */}
            <div className={style.tableContainer}>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th style={{ width: '100%' }}>{t("Type")}</th>
                            <th>{t("Rarity")}</th>
                            <th style={{ display: 'flex', alignItems: 'center' }} ><img src={CoinIcon} style={{ width: 13, height: 13, marginRight: 5 }} /> {t("earned")}</th>
                            <th>USDT {t("earned")}</th>
                            <th>PIX {t("earned")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            nfts.map((nftBlock, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td colSpan={5} onClick={() => toggleRow(nftBlock.name)}>
                                            <div className={style.table_tbRow}>
                                                <img src={nftBlock.name == 'Licences' ? LicenceNftIcon : nftBlock.name == 'Lands' ? LandsNftIcon : HousesNftIcon} className={style.tableImage} alt="NFT" />
                                                <span className={style.table_title}>{nftBlock.name}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <img
                                                src={smallArrowDown}
                                                alt="Expand"
                                                className={expandedRows.includes(nftBlock.name) ? style.arrowDownExpanded : style.arrowDown}
                                                onClick={() => toggleRow(nftBlock.name)}
                                            />
                                        </td>
                                    </tr>
                                    {
                                        expandedRows.includes(nftBlock.name) &&
                                        nftBlock.nfts.map(nft => (
                                            <tr key={nft.id}>
                                                <td>
                                                    <span>{nft.id}</span>
                                                </td>
                                                <td>
                                                    <div className={style.table_tbRow} onClick={() => navigate(`/predict/profile/my-nfts/${nft.id}`)}>
                                                        <img src={nft.name == 'Licences' ? LicenceNftIcon : nft.name == 'Lands' ? LandsNftIcon : nft.rarity == 'Diamond' ? HousesDiamondNftIcon : HousesNftIcon} className={style.tableImage} />
                                                        <span className={style.table_title}>{nft.name}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={style.table_tbColumn}>
                                                        <span>{nft.rarity}</span>
                                                        {/* <span className={style.positive}>{row.pricePercent}</span> */}
                                                    </div>
                                                </td>
                                                <td >
                                                    <div className={style.table_tbColumn}>
                                                        <span>{(+nft.balance_total).toFixed(2)}</span>
                                                        {/* <span className={style.positive}>{row.pixEarnedPercent}</span> */}
                                                    </div>
                                                </td>
                                                <td >
                                                    <div className={style.table_tbColumn}>
                                                        <span>{(+nft.balance_total_usdt).toFixed(3)}</span>
                                                        {/* <span className={style.positive}>0</span> */}
                                                    </div>
                                                </td>
                                                <td >
                                                    <div className={style.table_tbColumn}>
                                                        <span>0</span>
                                                        {/* <span className={style.positive}>0</span> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </React.Fragment>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            {
                profileNftFilterModalIsOpen &&
                <ProfileNftFilterModal onClose={() => setProfileNftFilterModalIsOpen(false)} />
            }
        </div>
    )
}

interface IProfileNftFilterModalProps {
    onClose: () => void;
}

function ProfileNftFilterModal(props: IProfileNftFilterModalProps) {
    return (
        <div className={style.profileNftsBlock_filterModal} onClick={() => props.onClose()}>
            <div className={style.profileNftsBlock_filterModal_content} onClick={e => e.stopPropagation()}>
                <div className={style.profileNftsBlock_filterModal_content_header}>
                    <div className={style.profileNftsBlock_filterModal_content_header_closeButton} onClick={props.onClose}>✕</div>
                </div>
                <div className={style.profileNftsBlock_filterModal_content_block}>
                    <div className={style.profileNftsBlock_filterModal_content_title}>
                        Floor Price ($)
                    </div>
                    <div className={style.profileNftsBlock_filterModal_content_grid}>
                        <div className={style.profileNftsBlock_filterModal_content_item}>
                            <input type='text' placeholder='$0' />
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_grid_divider}>
                            to
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_item}>
                            <input type='text' placeholder='$999,000' />
                        </div>
                    </div>

                    <div className={style.profileNftsBlock_filterModal_content_subtitle}>
                        Popular ranges
                    </div>

                    <div className={style.profileNftsBlock_filterModal_content_popularRanges}>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            51k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            57k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            63k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            100k
                        </div>
                    </div>
                </div>

                <div className={style.profileNftsBlock_filterModal_content_divider} />

                <div className={style.profileNftsBlock_filterModal_content_block}>
                    <div className={style.profileNftsBlock_filterModal_content_title}>
                        Type
                        <img src={smallArrowDown} alt="smallArrowDown" />
                    </div>
                    <div className={style.profileNftsBlock_filterModal_content_grid}>
                        <div className={style.profileNftsBlock_filterModal_content_item}>
                            <input type='text' placeholder='$0' />
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_grid_divider}>
                            to
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_item}>
                            <input type='text' placeholder='$999,000' />
                        </div>
                    </div>

                    <div className={style.profileNftsBlock_filterModal_content_subtitle}>
                        Popular ranges
                    </div>

                    <div className={style.profileNftsBlock_filterModal_content_popularRanges}>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            51k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            57k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            63k
                        </div>
                        <div className={style.profileNftsBlock_filterModal_content_popularRanges_item}>
                            100k
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
