import './profileStyle.scss'
import WithdrawIcon from '../../../../Resources/Predict/icons/withdraw-icon.svg'
import DepositIcon from '../../../../Resources/Predict/icons/deposit.svg'
import WithdrawBlackIcon from '../../../../Resources/Predict/icons/withdraw-black-icon.svg'
import DepositBlackIcon from '../../../../Resources/Predict/icons/deposit-black.svg'
import { useTheme } from '../../../../Context/Predict/themeContext'
import BackIcon from '../../../../Resources/Predict/icons/back.svg';
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useMemo, useState } from 'react'
import GameContext from '../../../../Context/GameContext'
import CoinIcon from '../../../../Resources/Predict/images/coin.svg';
import СoinBrownIcon from '../../../../Resources/Predict/icons/coin_brown.svg';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg';
import LockUsdtIcon from '../../../../Resources/Predict/icons/lock-usdt.svg';
import TransferIcon from '../../../../Resources/Predict/icons/transfer-icon.svg';
import TransferBlackIcon from '../../../../Resources/Predict/icons/transfer-black-icon.svg';
import { InvoiceProvider } from '../../../../Domain/Invoice/invoiceProvider'
import { ProfileGameHistoryBlock } from '../history/gameHistory'
import { PredictProvider } from '../../../../Domain/Predict/predictProdiver'
import InvoicePaymentModal from '../invoicePayment/invoicePaymentModal'

export default function Profile() {
    const { score, dao_balance, balance_usdt, balance_usdt_staking, token, updateGame } = useContext(GameContext);
    const predictBalance = useMemo(() => parseFloat((score / 1_000_000).toFixed(4)), [score])

    const { theme } = useTheme();
    const navigate = useNavigate();

    const [isOpenInvoicePaymentModal, setIsOpenInvoicePaymentModal] = useState<boolean>(false);

    useEffect(() => {
        updateBalance();
    }, [])

    async function updateBalance() {
        const balanceResult = await PredictProvider.getBalance(token!);
        updateGame({
            score: parseFloat(balanceResult.balance.toString()),
            balance_usdt: parseFloat(balanceResult.balance_usdt.toString()),
            dao_balance: balanceResult.dao_balance,
            balance_usdt_staking: balanceResult.balance_usdt_staking
        });
    }

    function formatNumber(value: number): string {
        if (Number.isInteger(+value)) {
            return value.toString();
        }
        return (+value).toFixed(4);
    }

    async function createDeposit() {
        setIsOpenInvoicePaymentModal(true);
    }

    async function withdraw() {
        navigate(`/predict/profile/withdraw`)
    }

    return (
        <div className='new-profile-container'>
            {isOpenInvoicePaymentModal && <InvoicePaymentModal onClose={() => setIsOpenInvoicePaymentModal(false)} />}
            <div className='new-profile-container-header' onClick={() => navigate(-1)}>
                <img src={BackIcon} alt="BackIcon" />
            </div>

            <div className='new-profile-container-header-buttons'>
                <div onClick={createDeposit}>Deposit <img src={theme == "light" ? DepositBlackIcon : DepositIcon} /></div>
                <div onClick={() => navigate("/predict/profile/swap")}>Swap <img src={theme == "light" ? TransferBlackIcon : TransferIcon} /></div>
                <div onClick={withdraw}>Withdraw <img src={theme == "light" ? WithdrawBlackIcon : WithdrawIcon} /></div>
            </div>

            <div className='new-profile-container-coins coins'>
                <div className='coins-item' onClick={() => navigate("/predict/profile/history/pixie")}>
                    <div>
                        <img src={CoinIcon} />
                        <span>Pixie</span>
                    </div>
                    <span>
                        {formatNumber(predictBalance)}
                    </span>
                </div>
                <div className='coins-item'>
                    <div>
                        <img src={СoinBrownIcon} />
                        <span>
                            DAO
                        </span>
                    </div>
                    <span>
                        {formatNumber(dao_balance)}
                    </span>
                </div>
                <div className='coins-item' onClick={() => navigate("/predict/profile/history/usdt")}>
                    <div>
                        <img src={UsdtIcon} />
                        <span>USDT</span>
                    </div>
                    <span>
                        {formatNumber(balance_usdt)}
                    </span>
                </div>
                <div className='coins-item' onClick={() => navigate("/predict/profile/history/lock-usdt")}>
                    <div>
                        <img src={LockUsdtIcon} />
                        <span>Lock USDT</span>
                        <div className='coins-item-badge'>100%/year</div>
                    </div>
                    <span>
                        {formatNumber(balance_usdt_staking)}
                    </span>
                </div>
            </div>

            <div style={{ marginTop: 50 }}>
                <ProfileGameHistoryBlock />
            </div>
        </div>
    )
}
