import { useContext, useEffect, useState } from 'react'
import Modal from '../../../../../Components/Predict/modal/modal'
import GameContext from '../../../../../Context/GameContext';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../Components/Predict/card/card';
import CoinIcon from '../../../../../Resources/Predict/images/coin.svg'
import UsdtIcon from '../../../../../Resources/Predict/icons/USDT.svg'
import PartyIcon from '../../../../../Resources/Predict/icons/party.svg'
import style from './resultModalStyle.module.scss';
import { GameProcess } from '../../../../../Domain/Predict/gameProcess';
import { gameResultType } from '../card/predictionCard';

interface IProps {
    player_price_bet: number | null,
    player_price_win: number | null,
    price_last: number | null,
    price_locked: number | null,
    in_game: boolean | null,
    game_id: number,
    game_name: string,
    coef_up: number | null,
    coef_up_usdt: number | null,
    coef_down: number | null,
    coef_down_usdt: number | null,
    isOpen: boolean;
    coinType: string;
    onClose: () => void;
}

export function PredictionResultModal(props: IProps) {
    const { userId } = useContext(GameContext);
    const [shareText, setShareText] = useState<string>("");

    const { t, i18n } = useTranslation();

    useEffect(() => {
        loadShareText();
        console.log(props)
    }, []);


    function loadShareText() {
        if (props.player_price_bet == null) return;
        if (props.player_price_win == null) return;

        if (i18n.language === 'ru') {
            setShareText(`Я поставил ${(+props.player_price_bet!)} ${props.coinType == "USDT" ? 'usdt' : "монет"} и выиграл ${+props.player_price_win!}! Заходи и играй со мной!`)
        } else if (i18n.language === 'uk') {
            setShareText(`Я поставив ${(+props.player_price_bet!)}  ${props.coinType == "USDT" ? 'usdt' : "монет"} і виграв ${+props.player_price_win!}! Заходь і грай зі мною!`)
        } else {
            setShareText(`I bet ${(+props.player_price_bet!)}  ${props.coinType == "USDT" ? 'usdt' : "coins"} and won ${+props.player_price_win!}! Come and play with me!`)
        }
    }

    // function isWinGame() {
    //     return props.player_price_win != null ? true : (props.coef_up == null || props.price_last == props.price_locked) ? null : false;
    // }

    function getGameResultType() {
        return props.player_price_win != null
            ? gameResultType.win
            : (props.coinType == "USDT" ? props.coef_up_usdt == null : props.coef_up == null)
                ? gameResultType.notEnoughPlayers
                : props.price_last == props.price_locked
                    ? gameResultType.withoutСhanges
                    : gameResultType.lose;
    }


    const priceDifference = (props.price_last ?? 0) - (props.price_locked ?? 0);

    function formatPriceDifference() {
        const difference = priceDifference < 0 ? priceDifference * -1 : priceDifference;

        return (difference < 1 && difference > -1)
            ? difference.toFixed([59, 60, 61].includes(props.game_id) ? 6 : 3)
            : Math.round(difference)
    }

    const isBTC = +props.game_id == 1;

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <Card className={style.resultModalContainer}>
                <div className={`${style.resultModalContainer_title} 
                ${[gameResultType.notEnoughPlayers, gameResultType.withoutСhanges].includes(getGameResultType()) ? style.gray : getGameResultType() == gameResultType.win ? style.green : style.red}`}>
                    {
                        getGameResultType() == gameResultType.withoutСhanges
                            ? t('Without changes')
                            : getGameResultType() == gameResultType.notEnoughPlayers
                                ? t('Not enough players')
                                : getGameResultType() == gameResultType.win ? t('You Won!') : t('Try again')
                    }
                    {getGameResultType() == gameResultType.win && <img src={PartyIcon} alt="PartyIcon" />}
                </div>
                <div className={style.resultModalContainer_mainResult}>
                    <img src={props.coinType == "USDT" ? UsdtIcon : CoinIcon} />
                    {
                        getGameResultType() == gameResultType.win && <span className={style.resultModalContainer_mainResult_coeff}>
                            {
                                props.in_game
                                    ? props.coef_up != null ? <span>x{(props.coinType == "USDT" ? +props.coef_up_usdt! : +props.coef_up).toFixed(2)}</span> : null
                                    : props.coef_down != null ? <span>x{(props.coinType == "USDT" ? +props.coef_down_usdt! : +props.coef_down).toFixed(2)}</span> : null
                            }
                        </span>
                    }
                    <span className={`${style.resultModalContainer_mainResult_main} ${getGameResultType() == gameResultType.lose ? style.fail : ""}`}>
                        {[gameResultType.notEnoughPlayers, gameResultType.withoutСhanges].includes(getGameResultType()) ? " " : getGameResultType() == gameResultType.win ? "+" : "-"}
                        {[gameResultType.notEnoughPlayers, gameResultType.withoutСhanges].includes(getGameResultType()) ? "0" : getGameResultType() == gameResultType.win ? (+(+props.player_price_win!).toFixed(2)).toLocaleString('en') : (+(+props.player_price_bet!).toFixed(2)).toLocaleString('en')}
                    </span>
                </div>
                {
                    props.price_last != null && props.price_locked != null &&
                    <div className={style.resultModalContainer_informationContainer}>
                        <div className={style.resultModalContainer_informationContainer_item}>
                            {
                                priceDifference == 0
                                    ? <span>{props.game_name} {t(' dont rose')} ${formatPriceDifference()}</span>
                                    : priceDifference > 0
                                        ? <span>{props.game_name} {t(' rose by')} ${formatPriceDifference()}</span>
                                        : <span>{props.game_name} {t(' fell by')} ${formatPriceDifference()}</span>
                            }
                        </div>
                    </div>
                }
                {
                    [gameResultType.notEnoughPlayers, gameResultType.withoutСhanges, gameResultType.lose].includes(getGameResultType()) &&
                    <div className={style.resultModalContainer_buttons_button} onClick={props.onClose}>
                        Ok
                    </div>
                }

                {
                    getGameResultType() == gameResultType.win &&
                    <div className={style.resultModalContainer_buttons}>
                        <div className={style.resultModalContainer_buttons_button} onClick={props.onClose}>
                            Ok
                        </div>

                        <div className={style.resultModalContainer_buttons_button}>
                            <a className={style.resultModalContainer_replayLink} href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${1}_${userId}&text=${shareText}`}>
                                {t('Share result')}
                            </a>
                        </div>
                    </div>
                }
            </Card>
        </Modal>
    )
}
