import skin_0 from "./Resources/images/skins/0.png";
import skin_1 from "./Resources/images/skins/1.png";
import skin_2 from "./Resources/images/skins/2.png";
import skin_3 from "./Resources/images/skins/3.png";
import skin_4 from "./Resources/images/skins/4.png";
import skin_5 from "./Resources/images/skins/5.png";
import skin_6 from "./Resources/images/skins/6.png";
import skin_7 from "./Resources/images/skins/7.png";
import skin_8 from "./Resources/images/skins/8.png";
import skin_9 from "./Resources/images/skins/9.png";
import skin_10 from "./Resources/images/skins/10.png";
import skin_11 from "./Resources/images/skins/11.png";
import skin_12 from "./Resources/images/skins/12.png";
import skin_13 from "./Resources/images/skins/13.png";
import skin_14 from "./Resources/images/skins/14.png";
import skin_15 from "./Resources/images/skins/15.png";
import skin_16 from "./Resources/images/skins/16.png";
import skin_17 from "./Resources/images/skins/17.png";
import skin_18 from "./Resources/images/skins/18.png";
import skin_19 from "./Resources/images/skins/19.png";
import skin_20 from "./Resources/images/skins/20.png";
import skin_21 from "./Resources/images/skins/21.png";
import skin_22 from "./Resources/images/skins/22.png";
import skin_23 from "./Resources/images/skins/23.png";
import skin_24 from "./Resources/images/skins/24.png";
import skin_25 from "./Resources/images/skins/25.png";
import skin_26 from "./Resources/images/skins/26.png";
import skin_27 from "./Resources/images/skins/27.png";

import stoneCoin1 from "./Resources/images/coins/stone-1.svg";
import stoneCoin2 from "./Resources/images/coins/stone-2.svg";
import woodenCoin1 from "./Resources/images/coins/wooden-1.svg";
import bronzeCoin1 from "./Resources/images/coins/bronze-1.svg";
import silverCoin1 from "./Resources/images/coins/silver-1.svg";
import pillerCoin from "./Resources/images/coins/pillerCoin.png";
import goldenCoin1 from "./Resources/images/coins/gold-1.svg";
import goldenCoin2 from "./Resources/images/coins/gold-2.svg";
import goldenPixieEventCoin from "./Resources/images/coins/gold-pixie-event.jpg";
import aterenum_coin from "./Resources/images/coins/aterenum.jpg";
import cryptowolf_coin from "./Resources/images/coins/ukraine.png";
import hamster_combat_coin from "./Resources/images/coins/vip.png";

export const skins_images = [
    skin_0,
    skin_1,
    skin_2,
    skin_3,
    skin_4,
    skin_5,
    skin_6,
    skin_7,
    skin_8,
    skin_9,
    skin_10,
    skin_11,
    skin_12,
    skin_13,
    skin_14,
    skin_15,
    skin_16,
    skin_17,
    skin_18,
    skin_19,
    skin_20,
    skin_21,
    skin_22,
    skin_23,
    skin_24,
    skin_25,
    skin_26,
    skin_27,
];

export const levels_score = [
    [0, 100_000],
    [100_001, 1_000_000],
    [1_000_001, 5_000_000],
    [5_000_001, 10_000_000],
    [10_000_001, 20_000_000],
    [20_000_001, 30_000_000],
    [30_000_001, 50_000_000],
    [50_000_001, 75_000_000],
    [75_000_001, 100_000_000],
    [100_000_001, 150_000_000],
    [150_000_001, 200_000_000],
    [200_000_001, 300_000_000],
    [300_000_001, 400_000_000],
    [400_000_001, 600_000_000],
    [600_000_001, 1_000_000_000],
    [1_000_000_001, 1_500_000_000],
    [1_500_000_001, 2_000_000_000],
    [2_000_000_001, 3_000_000_000],
    [3_000_000_001, 5_000_000_000],
    [5_000_000_001, 10_000_000_000],
    [10_000_000_001, 20_000_000_000],
    [20_000_000_001, 50_000_000_000],
    [50_000_000_001, 100_000_000_000],
];

export const coins_images = [
    "",
    "",
    stoneCoin1,
    stoneCoin2,
    woodenCoin1,
    bronzeCoin1,
    silverCoin1,
    goldenCoin1,
    goldenCoin2,
    goldenPixieEventCoin,
    aterenum_coin,
    cryptowolf_coin,
    hamster_combat_coin,
    pillerCoin
];