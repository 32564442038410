import { ActiveGame, mapToActiveGame } from "../Predict/acitveGame";
import { mapToUserBalanceHistory, UserBalanceHistory } from "./userBalanceHistory";
import { mapToUserGameHistory, UserGameHistory } from "./userGameHistory";

export class UserProvider {
    public static async authUser(
        userId: number, user_language: string, start_param: string | undefined,
        first_name: string | undefined, last_name: string | undefined, username: string | undefined,
        language_code: string | undefined,
    ): Promise<any> {
        const url = new URL(`${process.env.REACT_APP_API_URL}/v2/auth/user/get/${userId}/${user_language}`);
        start_param && url.searchParams.append('referal_code', start_param);
        first_name && url.searchParams.append('telegram_user_first_name', first_name);
        last_name && url.searchParams.append('telegram_user_last_name', last_name);
        username && url.searchParams.append('telegram_user_username', username);
        language_code && url.searchParams.append('telegram_user_locale', language_code);

        const any = await fetch(url)
            .then(response => response.json())
            .then(response => {
                return response;
            })

        return any;
    }

    public static async getGameHistory(token: string): Promise<{ gameHistory: UserGameHistory[], activeGames: ActiveGame[], currentDateTime: Date }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v2/game/history`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return {
            gameHistory: (response.history as any[]).map(mapToUserGameHistory),
            activeGames: (response.active_games as any[]).map(mapToActiveGame),
            currentDateTime: new Date(response.datetime_now)
        }
    }

    public static async getBalanceHistory(token: string): Promise<UserBalanceHistory[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/balance-history/get`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return (response.history as any[]).map(mapToUserBalanceHistory)
    }

    public static async getUsdtBalanceHistory(token: string): Promise<UserBalanceHistory[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/balance/history?coin=usdt`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return (response.history as any[]).map(mapToUserBalanceHistory)
    }

    public static async getUsdtLockBalanceHistory(token: string): Promise<UserBalanceHistory[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/balance/history?coin=usdt_staking`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return (response.history as any[]).map(mapToUserBalanceHistory)
    }
}