import React, { useContext, useEffect, useRef, useState } from 'react'
import BalanceCard from '../balanceCard/balanceCard'
import style from './style.module.scss'
import PixieLogo from '../../../../Resources/Predict/images/PixieLogo.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '../../../../Context/Predict/themeContext';
import GameContext from '../../../../Context/GameContext';
import { disableVerticalSwipes, enableVerticalSwipes } from "@telegram-apps/sdk-react";
import CoinIcon from '../../../../Resources/Predict/icons/coin.svg';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg';
import { BetModal } from './betModal/betModal';
import { PredictionFillingGlassResultModal } from './resultModal/resultModal';
import toast, { Toaster } from 'react-hot-toast';
import { GameProcess } from '../../../../Domain/Predict/gameProcess';
import { PredictProvider } from '../../../../Domain/Predict/predictProdiver';
import { FillingGlassGameHistory } from '../../../../Domain/Predict/fillingGlassGameHistory';
import PLoader from '../../../../Components/Predict/loader/Loader';
import { useTranslation } from 'react-i18next';

export function FillingGlass() {
    const { id } = useParams();

    const gameDurationInSeconds = (10 * 60) + 5;

    const { token, score, updateGame, userId, dao_balance } = useContext(GameContext);
    const [isOpenBetModal, setIsOpenBetModal] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isOpenResultModal, setIsOpenResultModal] = useState<boolean>(false);
    const [haveUpdateBalanceTimeOut, setHaveUpdateBalanceTimeOut] = useState<boolean>(false);

    const [gameProcess, setGameProcess] = useState<GameProcess | null>(null);
    const [fillingGlassGameHistory, setFillingGlassGameHistory] = useState<FillingGlassGameHistory[]>([]);
    const [openAccordions, setOpenAccordions] = useState<number | null>(null);

    const [timerTime, setTimerTime] = useState<number>(0);
    const timerTimeRef = useRef<number>(timerTime);

    const { theme } = useTheme();
    const { t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        timerTimeRef.current = timerTime;
    }, [timerTime]);

    useEffect(() => {
        if (disableVerticalSwipes.isAvailable()) {
            disableVerticalSwipes();
        }

        const intervalTimerId = setInterval(() => { setTimerTime((prevTime) => prevTime <= 0 ? 0 : prevTime - 1); }, 1_000);
        const updateGameProcessInterval = setInterval(() => { loadGameProcess(); }, 5_000);

        loadGameProcess();

        return () => {
            clearInterval(intervalTimerId)
            clearInterval(updateGameProcessInterval)

            if (enableVerticalSwipes.isAvailable()) {
                enableVerticalSwipes();
            }
        }
    }, [])

    async function loadGameProcess() {
        if (id == null) return;

        const { gameProcess, fillingGlassGameHistory } = await PredictProvider.getFillingGlassGameProcess(+id, token!);
        setGameProcess(gameProcess);
        setFillingGlassGameHistory(fillingGlassGameHistory);

        setIsLoaded(true)

        if (gameProcess.type == 1) return;
        if (gameProcess.type == 2) {
            if (timerTimeRef.current === 0) {
                setTimeout(() => {
                    updateBalance();
                }, gameProcess.time_to_end_second * 1000)
            }
            setTimerTime(gameProcess.time_to_end_second);
        }
    }

    function closeBetModal(result: boolean | null) {
        setIsOpenBetModal(false);
        if (result == null) return;

        if (result == true) {
            toast.success(t("Success!"));
            loadGameProcess();
        } else {
            toast.error("Error :c");
        }
    }

    function formatTime(seconds: number) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }

    function openBetModal() {
        setIsOpenBetModal(true);
    }

    async function updateBalance() {
        const balanceResult = await PredictProvider.getBalance(token!);
        updateGame({
            score: parseFloat(balanceResult.balance.toString()),
            balance_usdt: parseFloat(balanceResult.balance_usdt.toString()),
            dao_balance: balanceResult.dao_balance,
            balance_usdt_staking: balanceResult.balance_usdt_staking
        });
    }

    const toggleAccordion = (id: number) => {
        setOpenAccordions(openAccordions === id ? null : id);
    };

    const circleRadius = 115;
    const circleCircumference = 2 * Math.PI * circleRadius;
    const progressOffset = ((gameDurationInSeconds - timerTime) / gameDurationInSeconds) * circleCircumference;

    if (!isLoaded) return <PLoader />

    return (
        <div className={style.mainPageContainer}>
            <div className={style.header}>
                <div className={style.header_logo} onClick={() => navigate("/predict/predictions")}>
                    <img src={PixieLogo} alt="PixieLogo" />
                </div>
                <div className={style.header_rightBlock}>
                    <BalanceCard />
                </div>
            </div>

            <div className={style.timerContainer}>
                <div className={style.circleContainer}>
                    <svg className={style.timerCircle} width="250" height="250">
                        <circle
                            className={style.backgroundCircle}
                            cx="125"
                            cy="125"
                            r={circleRadius}
                        />
                        <circle
                            className={style.progressCircle}
                            cx="125"
                            cy="125"
                            r={circleRadius}
                            style={{
                                strokeDasharray: circleCircumference,
                                strokeDashoffset: progressOffset,
                            }}
                        />
                    </svg>
                    <div className={style.timer}>
                        {formatTime(timerTime)}
                    </div>
                    <div className={style.totalAmount}>
                        <div>
                            <span>{(+((gameProcess?.prize_pool ?? 0).toFixed(2))).toLocaleString('en')}</span>
                            <img src={CoinIcon} alt="Coin Icon" className={style.coinIcon} />
                        </div>
                        <div>
                            <span>{(+((gameProcess?.prize_pool_usdt ?? 0).toFixed(2))).toLocaleString('en')}</span>
                            <img src={UsdtIcon} alt="Usdt Icon" className={style.coinIcon} />
                        </div>
                    </div>
                </div>
                <button className={style.betButton} onClick={openBetModal}>Сделать ставку</button>
            </div>

            <div className={style.betsTable}>
                {
                    fillingGlassGameHistory.map((history, index) => (
                        <div key={index} className={style.betRow}>
                            <div className={style.betRow_header} onClick={() => toggleAccordion(history.id)}>
                                <span className={style.nickname}>#{history.id}</span>
                                <div className={style.amount}>
                                    <span>{(history.total).toFixed(3)}</span>
                                    <img src={CoinIcon} alt="Coin Icon" className={style.coinIcon} />
                                </div>
                            </div>
                            {
                                openAccordions === history.id && (
                                    <div className={style.playersList}>
                                        {history.players.map((player, playerIndex) => (
                                            <div key={playerIndex} className={style.playerRow}>
                                                <span className={style.playerName}>
                                                    {player.first_name == null && player.last_name == null
                                                        ? "?"
                                                        : <>{player.first_name} {player.last_name}</>
                                                    }
                                                </span>
                                                <div className={style.amount}>
                                                    <span>{(+player.sum).toFixed(3)}</span>
                                                    <img src={CoinIcon} alt="Coin Icon" className={style.coinIcon} />
                                                </div>
                                                <span className={style.percentage}>
                                                    {((player.sum / history.total) * 100).toFixed(1)}%
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )
                            }
                        </div>
                    ))
                }
            </div>

            {
                isOpenBetModal && gameProcess &&
                <BetModal isOpen={isOpenBetModal} onClose={closeBetModal} gameProcess={gameProcess} />
            }

            {
                isOpenResultModal &&
                <PredictionFillingGlassResultModal isOpen={isOpenResultModal} onClose={() => setIsOpenResultModal(false)} />
            }

            <div><Toaster /></div>
        </div>
    )
}
