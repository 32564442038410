import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import GameContext from "../../../../Context/GameContext";
import { PredictLeadBoardProvider } from "../../../../Domain/Predict/leadboard/leadbordProvider";
import style from './preidctLeadBoardStyle.module.scss'
import { useNavigate } from "react-router-dom";
import PixieLogo from '../../../../Resources/Predict/images/PixieLogo.png';
import BalanceCard from "../balanceCard/balanceCard";
import { Claim } from "../../../../Domain/Claim/clima";
import toast, { Toaster } from "react-hot-toast";
import PixLeaderBoard from "./leaderBoard";
import LeaderBoard from "./leaderBoard";

enum CoinType {
    Pix = 1,
    USDT = 2
}

export default function PredictLeaderBoard() {
    const [claims, setClaims] = useState<Claim[]>([]);
    const [selectedCoinType, setSelectedCoinType] = useState<CoinType>(CoinType.Pix);

    useEffect(() => {
        loadClaims();
    }, []);

    const { t } = useTranslation();
    const { token } = useContext(GameContext);

    async function loadClaims() {
        const claims = await PredictLeadBoardProvider.getClaims(token!);
        setClaims(claims)
    }

    const navigate = useNavigate();

    async function onClaim() {
        await loadClaims();
        toast.success(t("Success"));
    }

    return (
        <div className={style.mainPageContainer}>
            <div className={style.header}>
                <div className={style.header_left}>
                    <div className={style.header_left_logo} onClick={() => navigate("/predict/predictions")}>
                        <img src={PixieLogo} alt="PixieLogo" />
                    </div>
                </div>
                <BalanceCard />
            </div>

            <div className={style.navigation}>
                <div className={`${style.navigation_item} ${selectedCoinType == CoinType.Pix ? style.active : ""}`} onClick={() => setSelectedCoinType(CoinType.Pix)}>
                    PIX
                </div>
                <div className={`${style.navigation_item}  ${selectedCoinType == CoinType.USDT ? style.active : ""}`} onClick={() => setSelectedCoinType(CoinType.USDT)}>
                    USDT
                </div>
            </div>
            <LeaderBoard
                coinType={selectedCoinType == CoinType.Pix ? 'pix' : 'usdt'}
                claims={selectedCoinType == CoinType.Pix ? claims.filter(c => c.coin_type == 'pixie') : claims.filter(c => c.coin_type == 'usdt')}
                onClaim={onClaim}
            />
            <div><Toaster /></div>
        </div>
    );
}
