import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import coinImage from "../../Resources/images/coin.svg";
import iceIcon from "../../Resources/images/coin-game/ice.svg";
import bombImage from "../../Resources/images/coin-game/bomb.svg";
import './family-game.css';
import { useNavigate } from 'react-router-dom';
import GameContext from "../../Context/GameContext";
import WebApp from "@twa-dev/sdk";
import { disableVerticalSwipes, enableVerticalSwipes } from "@telegram-apps/sdk-react";
import { EventProvider } from '../../Domain/Event/eventProvider';

interface Coin {
  id: number,
  left: number,
  size: string,
  type: CoinType
}

enum CoinType {
  bobm = 1,
  coin = 2,
  ice = 3
}

export default function FamilyGame() {
  const startGameDuration = 60_000;
  const startCointSpeed = 3_500;
  const startCoinInterval = 600;

  const [coinCount, setCoinCount] = useState<number>(0);
  const [coins, setCoins] = useState<Coin[]>([]);
  const [speed, setSpeed] = useState<number>(startCointSpeed);
  const [gameTime, setGameTime] = useState<number>(startGameDuration);
  const [coinInterval, setCoinInterval] = useState<number>(startCoinInterval);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [isFrozen, setIsFrozen] = useState<boolean>(false);
  const [isShowBombEffect, setIsShowBombEffect] = useState<boolean>(false);
  const [isCoinCountAnimating, setIsCoinCountAnimating] = useState<boolean>(false);

  const [timeToPlayAgain, setTimeToPlayAgainCoinGame] = useState<number>(0);
  const coinsContainerRef = useRef<HTMLDivElement>(null);

  const { token } = useContext(GameContext);
  const naviagate = useNavigate();

  function startGame() {
    setIsPlaying(true);
    setCoinCount(0);
    setCoins([]);
    setSpeed(startCointSpeed);
    setCoinInterval(startCoinInterval);
    setGameTime(startGameDuration);
  }

  function endGame() {
    setIsPlaying(false);
    setGameTime(0);
    setCoins([]);
    setSpeed(startCointSpeed);
    setCoinInterval(startCoinInterval);
    setIsGameOver(true);
  }

  useEffect(() => {
    if (!token) {
      naviagate("/families")
    }

    if (disableVerticalSwipes.isAvailable()) {
      disableVerticalSwipes();
    }

    return () => {
      loadDropCoinsResult();

      if (enableVerticalSwipes.isAvailable()) {
        enableVerticalSwipes();
      }
    }

  }, []);

  async function loadDropCoinsResult() {
    const response = await EventProvider.getDropCoinsResultList(token!);

    if (response.results.length == 0) {
      setTimeToPlayAgainCoinGame(0);
      return;
    }

    const lastGame = (response.results as any[]).reduce((latest, current) => {
      const latestDate = new Date(latest.created_at);
      const currentDate = new Date(current.created_at);
      return currentDate > latestDate ? current : latest;
    }, response.results[0]);

    const lastGameDate = new Date(lastGame.created_at);
    const now = new Date(response.now_datetime);

    const fourHoursLater = new Date(lastGameDate.getTime() + 4 * 60 * 60 * 1000);

    const timeRemaining = fourHoursLater.getTime() - now.getTime();
    const secondToStartGameAgain = Math.max(Math.floor(timeRemaining / 1000), 0);

    setTimeToPlayAgainCoinGame(secondToStartGameAgain);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeToPlayAgainCoinGame(prevTime => (prevTime != null && prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeToPlayAgain]);

  useEffect(() => {
    if (!isPlaying) return;

    const coinInterval = setInterval(() => {
      setCoinInterval(interval => interval > 180 ? interval - 50 : interval);
    }, 2000);

    const gameTimer = setInterval(() => {
      setGameTime(time => {
        const newTime = time - 1000;
        if (newTime <= 0) {
          endGame();
        }

        return newTime;
      });

    }, 1000);

    return () => {
      clearInterval(gameTimer);
      clearInterval(coinInterval);
    };
  }, [isPlaying]);

  useEffect(() => {
    if (!isPlaying) return;
    if (isFrozen) return;

    const coinGenerationInterval = setInterval(generateRandomItem, coinInterval);

    return () => clearInterval(coinGenerationInterval);
  }, [isPlaying, coinInterval, isFrozen]);

  useEffect(() => {
    if (!isPlaying) return;
    if (isFrozen) return;

    const coinSpeedIntervalReducer = setInterval(() => {
      setSpeed(speed => speed > 1_500 ? speed - 5 : speed);
    }, 50);

    return () => clearInterval(coinSpeedIntervalReducer);

  }, [isPlaying, isFrozen]);

  useEffect(() => {
    if (!isPlaying) return;

    const interval = setInterval(() => {
      const containerHeight = coinsContainerRef.current!.offsetHeight;

      setCoins(coins => coins.filter(coin => {
        const coinElement = document.getElementById(`coin-${coin.id}`);
        if (!coinElement) return false;

        const coinRect = coinElement.getBoundingClientRect();
        return coinRect.top < containerHeight;
      }));
    }, 100);

    return () => clearInterval(interval);
  }, [isPlaying]);

  useEffect(() => {
    if (!isGameOver) return;

    handleEndGame();

  }, [isGameOver])

  function generateRandomItem() {
    const id = Date.now();
    const sizes: ('small' | 'medium' | 'normal' | 'large')[] = ['small', 'medium', 'normal', 'large'];
    const size: 'small' | 'medium' | 'normal' | 'large' = sizes[Math.floor(Math.random() * sizes.length)];

    const sizeToVw: {
      small: number;
      medium: number;
      normal: number;
      large: number;
    } = {
      small: 10,
      medium: 12,
      normal: 14,
      large: 16,
    };

    const coinPadding = 5;
    const coinSizeVw = sizeToVw[size] + coinPadding;
    const screenWidth = window.innerWidth;

    // Переводим размер монеты из vw в пиксели
    const coinSizePx = (coinSizeVw / 100) * screenWidth;
    const maxLeft = screenWidth - coinSizePx;

    const left = Math.random() * maxLeft;
    let type = getRandomItemType();

    setCoins(coins => [...coins, { id, left, size, type }]);
  };

  function getRandomItemType(): CoinType {
    const randomNum = Math.random();

    if (randomNum < 0.85) {
      return CoinType.coin;
    } else if (randomNum < 0.97) {
      return CoinType.bobm;
    } else {
      return CoinType.ice;
    }
  }

  function catchItem(id: number, type: CoinType) {
    setCoins(coins => coins.filter(coin => coin.id !== id));

    if (type === CoinType.bobm) handleBomb()
    else if (type === CoinType.ice) handleFroze()
    else handleCoin()
  };

  function handleBomb() {
    if (WebApp) {
      WebApp.HapticFeedback.notificationOccurred('error');
    }

    setIsShowBombEffect(true);
    setTimeout(() => {
      setIsShowBombEffect(false);
    }, 1000);

    if (coinCount === 0) return;

    const newCointCount = coinCount - 10;
    const cointCount = newCointCount < 0 ? 0 : newCointCount;

    setCoinCount(cointCount);
  }

  function handleFroze() {
    if (WebApp) {
      WebApp.HapticFeedback.selectionChanged();
    }

    setIsFrozen(true);
    setTimeout(() => {
      setIsFrozen(false);
    }, 3000);
  }

  function handleCoin() {
    if (WebApp) {
      WebApp.HapticFeedback.impactOccurred('medium');
    }

    setCoinCount(count => count + 1);
    setIsCoinCountAnimating(true);
    setTimeout(() => setIsCoinCountAnimating(false), 150);
  }

  async function handleEndGame() {
    const response = await EventProvider.setDropCoins(coinCount, token!)
    if (response.status != 'ok') {
      console.log("error");
    }
  }

  function formatTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <div className={`container`}>
      <div className="coin-game-container">
        <div className='coin_count-container'>
          <div className={`coin_count_container-value ${isCoinCountAnimating ? 'animate' : ''} ${isShowBombEffect ? 'red' : ''}`}>
            <img src={coinImage} alt="coin" />
            {coinCount}
          </div>
          <div className="game-time">{`Time: ${gameTime / 1000}`}</div>
        </div>
        <div className={`falling-coins-container  ${isShowBombEffect ? 'shake' : ''}`} ref={coinsContainerRef}>
          {
            coins.map(coin => (
              <Coin
                key={coin.id}
                id={coin.id}
                left={coin.left}
                size={coin.size}
                speed={speed}
                type={coin.type}
                isPaused={isFrozen}
                onCatch={() => catchItem(coin.id, coin.type)}
              />
            ))
          }
        </div>

        {isFrozen && <div className='frozen-effect'></div>}
        {isShowBombEffect && <div className='bomb-effect'></div>}

        {
          !isPlaying && (
            <div className='play-button-container'>
              <button
                className='play-button'
                disabled={
                  timeToPlayAgain != 0
                }
                onClick={startGame}
              >
                PLAY
              </button>
              {
                timeToPlayAgain != 0 &&
                <div className="play-time">{formatTime(timeToPlayAgain)}</div>
              }
            </div>
          )
        }

        {
          !isPlaying && gameTime == 0 && (
            <EndGameModal coinCount={coinCount} onPlayAgain={startGame} />
          )
        }
        {/* {
          showContinueModal && (
            <ContinueGameModal
              coinCount={coinCount}
              gameTime={gameTime}
              onContinue={() => {
                setShowContinueModal(false);
                setIsPlaying(true);
              }}
            />
          )
        } */}
      </div>
    </div>
  )
}

interface ICoinProps {
  id: number;
  left: number;
  size: string;
  speed: number;
  type: CoinType;
  isPaused: boolean;
  onCatch: () => void;
}

function Coin(props: ICoinProps) {
  const [isStartCoinAnimate, setIsStartCoinAnimate] = useState<boolean>(false);
  const [showDamage, setShowDamage] = useState<boolean>(false);
  const [showBonus, setShowBonus] = useState<boolean>(false);

  const [isClicked, setIsClicked] = useState<boolean>(false);

  function handleClick(e: React.PointerEvent<HTMLDivElement>) {
    e.stopPropagation();

    if (isClicked) return;
    setIsClicked(true);

    setIsStartCoinAnimate(true);

    if (props.type === CoinType.bobm) {
      setShowDamage(true);
      setTimeout(() => setShowDamage(false), 100);
    }

    if (props.type === CoinType.coin) {
      setShowBonus(true);
    }

    setTimeout(() => {
      setShowBonus(false);
      setIsStartCoinAnimate(false);
      props.onCatch();
    }, 100);

  };

  const sizeClass = `coin-${props.size}`;

  const getImage = () => {
    switch (props.type) {
      case CoinType.bobm:
        return bombImage;
      case CoinType.ice:
        return iceIcon;
      default:
        return coinImage;
    }
  };

  return (
    <div
      id={`coin-${props.id}`}
      className={`coin ${sizeClass} ${isStartCoinAnimate ? 'coin-glow' : ''} ${props.isPaused ? 'paused' : ''}`}
      style={{ left: `${props.left}px`, animationDuration: `${props.speed}ms` }}
      onPointerDown={handleClick}
    >
      <img src={getImage()} alt={props.type.toString()} draggable="false" style={{ pointerEvents: 'none' }} />
      {showDamage && <div className="damage">-10</div>}
      {showBonus && <div className="bonus-text">+1</div>}
    </div>
  );
}

interface IEndGameModalProps {
  coinCount: number;
  onPlayAgain: () => void;
}

function EndGameModal(props: IEndGameModalProps) {
  const navigate = useNavigate();

  const [timeToPlayAgain, setTimeToPlayAgain] = useState<number>(4 * 60 * 60);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeToPlayAgain(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function formatTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  function onExitButton() {
    navigate(-1);

    setTimeout(() => {
      if (window.history.state && window.history.state.idx === 0) {
        navigate('/families');
      }
    }, 100);
  }

  return (
    <div className="modal-backdrop_container">
      <div className="modal-backdrop_container-content">
        <h2>Game Over</h2>
        <p>You collected {props.coinCount} coins!</p>
        <div className="modal-backdrop_action-buttons action-buttons">
          <div className="action-buttons_play-again-container">
            <button className="action-buttons_play-again-button"
              disabled={
                timeToPlayAgain != 0
              }
              onClick={props.onPlayAgain}
            >
              Play Again
            </button>
            {
              timeToPlayAgain != 0 &&
              <div className="action-buttons_play-again-time">{formatTime(timeToPlayAgain)}</div>
            }
          </div>
          <button className="action-exit-button" onClick={onExitButton}>Exit</button>
        </div>
      </div>
      <div className="offline_profit_container-overlay"></div>
    </div>
  );
}

// function ContinueGameModal({ coinCount, gameTime, onContinue }) {
//   return (
//     <div className="modal-backdrop_container">
//       <div className="modal-backdrop_container-content">
//         <h2>Game in Progress</h2>
//         <p>You have collected {coinCount} coins!</p>
//         <p>Time remaining: {Math.floor(gameTime / 1000)} seconds</p>
//         <button className="action-buttons_play-continue-button" onClick={onContinue}>
//           Continue
//         </button>
//       </div>
//       <div className="offline_profit_container-overlay"></div>
//     </div>
//   );
// }