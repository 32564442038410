import { useNavigate } from 'react-router-dom';
import style from './style.module.scss';
import { useContext, useMemo, useState } from 'react';
import GameContext from '../../../../Context/GameContext';
import CoinIcon from '../../../../Resources/Predict/images/coin.svg';
import СoinBrownIcon from '../../../../Resources/Predict/icons/coin_brown.svg';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg';
import LockUsdtIcon from '../../../../Resources/Predict/icons/lock-usdt.svg';
import { Currency } from '../../../../Providers/GameProvider';
import { useTranslation } from 'react-i18next';

export default function BalanceCard() {
    const { score, dao_balance, updateGame, balance_usdt, balance_usdt_staking } = useContext(GameContext);
    const predictBalance = useMemo(() => parseFloat((score / 1_000_000).toFixed(2)), [score])

    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const { t } = useTranslation();

    function toggleMenu(e: any) {
        e.stopPropagation();

        setMenuOpen(!menuOpen);
    };

    function handleMenuItemClick(e: any, currency: Currency) {
        e.stopPropagation();

        updateGame({ selectedCurrency: currency })
        setMenuOpen(false);
    };

    function formatNumber(value: number): string {
        if (Number.isInteger(+value)) {
            return value.toString();
        }
        return (+value).toFixed(2);
    }

    function parseBigNumber(number: number) {
        if (number >= 10_000 && number < 1_000_000) {
            return `${(+Math.round(number / 1000)).toLocaleString("en")}k`
        } else if (number >= 1_000_000) {
            return `${(+Math.round(number / 1_000_000)).toLocaleString("en")}kk`
        } else {
            return `${(formatNumber(number))}`;
        }
    }

    return (
        <div className={style.header_balance}>
            <div className={style.header_balance__content} onClick={() => navigate("/predict/profile/me")}>
                <div>
                    <img src={CoinIcon} alt="СoinIcon" />
                    <div className={style.header_balance_title}>
                        {parseBigNumber(predictBalance)}
                    </div>
                </div>
                <div>
                    <img src={СoinBrownIcon} alt="СoinIcon" />
                    <div className={style.header_balance_title}>
                        {parseBigNumber(dao_balance)}
                    </div>
                </div>
                <div>
                    <img src={UsdtIcon} alt="СoinIcon" />
                    <div className={style.header_balance_title}>
                        {parseBigNumber(balance_usdt)}
                    </div>
                </div>
                <div>
                    <img src={LockUsdtIcon} alt="СoinIcon" />
                    <div className={style.header_balance_title}>
                        {parseBigNumber(balance_usdt_staking ?? 0)}
                    </div>
                </div>
            </div>
        </div>
    )
}
