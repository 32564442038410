import './swapStyle.scss'
import BackIcon from '../../../../Resources/Predict/icons/back.svg';
import TransferIcon from '../../../../Resources/Predict/icons/transfer-icon.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import GameContext from '../../../../Context/GameContext';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg';
import LockUsdtIcon from '../../../../Resources/Predict/icons/lock-usdt.svg';
import PLoader from '../../../../Components/Predict/loader/Loader';
import toast, { Toaster } from "react-hot-toast";
import { PredictProvider } from '../../../../Domain/Predict/predictProdiver';
import SwapNotification from './swapNotification';

export default function Swap() {
    const { balance_usdt_staking, balance_usdt, token } = useContext(GameContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [selectedValue, setSelectedValue] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);


    function formatNumber(value: number): string {
        if (Number.isInteger(+value)) {
            return value.toString();
        }
        return (+value).toFixed(4);
    }

    function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        const normalizedValue = value ? value.replace(",", ".") : null;
        const numberValue = normalizedValue ? parseFloat(normalizedValue) : null;

        if (numberValue != null && numberValue > balance_usdt_staking) return;

        setSelectedValue(numberValue);
    }

    async function swap() {
        if (selectedValue == 0 || selectedValue == null) return toast.error("Specify the amount");

        setErrorMessage(null);
        setIsLoading(true);

        const result = await PredictProvider.swap(selectedValue, token!);
        if (result.message == "ok") {
            setIsOpenNotification(true);
        }
        else {
            setErrorMessage(result.message)
            setIsOpenNotification(true);
        }

        setIsLoading(false);
    }

    return (
        <div className='swap-container'>
            {isLoading && <PLoader />}
            {
                isOpenNotification &&
                <SwapNotification
                    message={errorMessage != null ? errorMessage : ""}
                    status={errorMessage != null ? 'error' : "success"}
                    onClose={errorMessage != null ? () => setIsOpenNotification(false) : () => navigate('/predict/profile/me')}
                />
            }
            <div className='swap-container-header'>
                <div className='swap-container-header-title'>
                    <div className='swap-container-header-title-back' onClick={() => navigate(-1)}>
                        <img src={BackIcon} alt="BackIcon" />
                    </div>
                    {t('Swap')}
                </div>
            </div>

            <div className='swap-container-blocks'>
                <div className='swap-container-blocks-block'>
                    <div className='swap-container-blocks-block-title'>
                        <span>From</span>
                        <span>Balance: {formatNumber(balance_usdt_staking)}</span>
                    </div>
                    <div className='swap-container-blocks-block-content'>
                        <div>
                            <input
                                type="number"
                                placeholder='0'
                                pattern="[0-9]+([,][0-9]{1,2})?"
                                value={selectedValue ?? undefined}
                                onChange={handleChangeValue}
                            />
                        </div>
                        <div>
                            <img src={LockUsdtIcon} alt="LockUsdtIcon" />
                            LOCK USDT
                        </div>
                    </div>
                </div>
                <div className='swap-container-blocks-divider'>
                    <img src={TransferIcon} />
                </div>
                <div className='swap-container-blocks-block'>
                    <div className='swap-container-blocks-block-title'>
                        <span>To</span>
                        <span>Balance: {formatNumber(balance_usdt)}</span>
                    </div>
                    <div className='swap-container-blocks-block-content'>
                        <div>

                        </div>
                        <div>
                            <img src={UsdtIcon} alt="UsdtIcon" />
                            USDT
                        </div>
                    </div>
                </div>
            </div>
            <div className='swap-container-swap-button' onClick={swap}>
                SWAP
            </div>
            <Toaster />
        </div>
    )
}
