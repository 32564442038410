import { useContext, useMemo, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { DownButton } from '../../../../../Components/Predict/actionButton/downButton'
import { UpButton } from '../../../../../Components/Predict/actionButton/upButton'
import { Card } from '../../../../../Components/Predict/card/card'
import { RoundedNumberInput } from '../../../../../Components/Predict/inputs/roundedTextInput/roundedNumberInput'
import PLoader from '../../../../../Components/Predict/loader/Loader'
import GameContext from '../../../../../Context/GameContext'
import { GameProcess } from '../../../../../Domain/Predict/gameProcess'
import { PredictProvider } from '../../../../../Domain/Predict/predictProdiver'
import DownRightArrowLightIcon from '../../../../../Resources/Predict/icons/downRightArrow_light.svg'
import linkIcon from '../../../../../Resources/Predict/icons/link2.svg'
import TopRightArrowLightIcon from '../../../../../Resources/Predict/icons/topRightArrow_light.svg'
import coinIcon from '../../../../../Resources/Predict/icons/coin.svg'
import UsdtIcon from '../../../../../Resources/Predict/icons/USDT.svg'
import style from './setPositionModalStyle.module.scss'
import { useTheme } from '../../../../../Context/Predict/themeContext'
import TopArrowIcon from '../../../../../Resources/Predict/icons/topArrow.svg'
import TopArrowDarkIcon from '../../../../../Resources/Predict/icons/topArrow_dark.svg'
import DownArrowIcon from '../../../../../Resources/Predict/icons/downArrow.svg'
import DownArrowDarkIcon from '../../../../../Resources/Predict/icons/downArrow2_dark.svg'
import { RoundedSelect } from '../../../../../Components/Predict/inputs/roundedSelect/roundedSelect'

export enum BetType {
    Down = 0,
    Up = 1,
}

export enum CoinType {
    Pix = 1,
    Usdt = 2,
}

interface SetPositionCardProps {
    betType: BetType;
    gameProcess: GameProcess;
    isOpen: boolean;
    onClose: (inBet: boolean, gameProcessId: number, betType: BetType, selectedCoinType: string, betPrice: number) => void;
}

export function SetPositionModal(props: SetPositionCardProps) {
    const { score, token, updateGame, dao_balance, balance_usdt } = useContext(GameContext);
    const predictBalance = useMemo(() => Math.round(score / 1_000_000), [score])
    const onlyUsdtPredictIds = [130, 131, 132, 169, 170];

    const [betPrice, setBetPrice] = useState<number | null>(null);
    const [isBetPriceNotSelected, setIsBetPriceNotSelected] = useState<boolean>(false);
    const [selectedCoinType, setSelectedCoinType] = useState<CoinType>(onlyUsdtPredictIds.includes(props.gameProcess.game_id) ? CoinType.Usdt : CoinType.Pix);

    const commit = useMemo(() => (betPrice ?? 0) * 0.99, [betPrice])
    // const fee = useMemo(() => (betPrice ?? 0) * 0.01, [betPrice]);

    const [isBetSending, setIsBetSending] = useState<boolean>(false);
    const { t } = useTranslation();
    const { theme } = useTheme();
    const coinTypes = [CoinType.Pix, CoinType.Usdt]

    const min05coinPredictId = 170;
    const min5coinPredictId = 130;
    const min1coinPredictId = 169;
    const min10coinPredictId = 131;
    const min50coinPredictId = 69;
    const min100coinPredictId = 132;
    const min500coinPredictId = 95;
    const min1000coinPredictId = 92;
    const min5000coinPredictId = 96;

    function changeValue(value: number | null) {
        setBetPrice(value);
    }

    async function bet() {
        if (betPrice == null || betPrice <= 0) {
            setIsBetPriceNotSelected(true);
            setTimeout(() => {
                setIsBetPriceNotSelected(false);
            }, 3000);

            return;
        }

        if (selectedCoinType == CoinType.Pix && onlyUsdtPredictIds.includes(props.gameProcess.game_id)) {
            return toast.error("You can only choose USDT");
        }

        if (props.gameProcess.game_id == min05coinPredictId) {
            if (betPrice < 0.5) {
                return toast.error("Min bet price 0.5")
            }
        } else if (props.gameProcess.game_id == min5coinPredictId) {
            if (betPrice < 5) {
                return toast.error("Min bet price 5")
            }
        } else if (props.gameProcess.game_id == min1coinPredictId) {
            if (betPrice < 1) {
                return toast.error("Min bet price 1")
            }
        } else if (props.gameProcess.game_id == min10coinPredictId) {
            if (betPrice < 10) {
                return toast.error("Min bet price 10")
            }
        } else if (props.gameProcess.game_id == min50coinPredictId) {
            if (betPrice < 50) {
                return toast.error("Min bet price 50")
            }
        } else if (props.gameProcess.game_id == min100coinPredictId) {
            if (betPrice < 100) {
                return toast.error("Min bet price 100")
            }
        } else if (props.gameProcess.game_id == min500coinPredictId) {
            if (betPrice < 500) {
                return toast.error("Min bet price 500")
            }
        } else if (props.gameProcess.game_id == min1000coinPredictId) {
            if (betPrice < 1_000) {
                return toast.error("Min bet price 1000")
            }
        }
        else if (props.gameProcess.game_id == min5000coinPredictId) {
            if (betPrice < 5_000) {
                return toast.error("Min bet price 5000")
            }
        }

        setIsBetSending(true);
        const response = await PredictProvider.bet(props.betType, props.gameProcess.id, betPrice, translatedName(selectedCoinType), token!);
        if (response.message == 'ok') {
            updateGame({
                score: selectedCoinType == CoinType.Pix ? score - (betPrice ?? 0) * 1_000_000 : score,
                balance_usdt: selectedCoinType == CoinType.Usdt ? balance_usdt - (betPrice ?? 0) : balance_usdt,
                dao_balance: dao_balance + (+response.dao_bonus_for_bet)
            })
            props.onClose(true, props.gameProcess.id, props.betType, translatedName(selectedCoinType), betPrice);
        }
        else {
            toast.error(t("Error :c"));
            props.onClose(false, props.gameProcess.id, props.betType, translatedName(selectedCoinType), betPrice);
        }

        setIsBetSending(false);
    }

    const canBet = () => {
        if (isBetSending) return false;
        if ((betPrice ?? 0) > (selectedCoinType == CoinType.Pix ? predictBalance : balance_usdt)) return false;
        if ((betPrice ?? 0) <= 0) return false;

        return true;
    }

    function translatedName(coinType: CoinType | null) {
        if (coinType == null) return "";

        switch (coinType) {
            case CoinType.Pix: return "PIX";
            case CoinType.Usdt: return "USDT";
        }
    }

    function changeCoinType(type: CoinType) {
        setSelectedCoinType(type ?? CoinType.Pix);

        if (type == CoinType.Pix && (betPrice ?? 0) > predictBalance) {
            setBetPrice(0);
        }

        if (type == CoinType.Usdt && (betPrice ?? 0) > balance_usdt) {
            setBetPrice(0);
        }

    }

    if (isBetSending) return <PLoader />

    return (
        <div className={style.overlay} style={{ marginTop: 70 }}>
            <Card className={style.modalContainerCard}>
                <div className={style.modalContainerCard_headerButton}>
                    <span>
                        <span>#{props.gameProcess.id}&nbsp;</span>
                        {t('Set Position')}
                    </span>
                    <div onClick={() => props.onClose(false, props.gameProcess.id, props.betType, translatedName(selectedCoinType), (betPrice ?? 0))}>✕</div>
                </div>
                <div className={style.predictionContainer}>
                    <div className={`${style.predictionContainer__informationBlock} ${style.informationBlock}`}>
                        <div className={style.informationBlock_circle_badgeUp}>
                            {
                                props.betType == BetType.Up
                                    ?
                                    <>
                                        <span>{t('UP')}</span>
                                        <img src={TopRightArrowLightIcon} alt="TopRightArrow" />
                                    </>
                                    :
                                    <>
                                        <span>{t('DOWN')}</span>
                                        <img src={DownRightArrowLightIcon} alt="DownRightArrowLightIcon" />
                                    </>
                            }
                        </div>
                        <div className={`${style.informationBlock_rightBlock_flex} ${style.rightBlock_flex}`}>
                            <div className={style.rightBlock_flex__element}>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Commit')}</div>
                                    <div className={style.rightBlock_flex__subTitle}>
                                        <span>
                                            {
                                                selectedCoinType == CoinType.Pix
                                                    ? <img src={coinIcon} alt="coinIcon" />
                                                    : <img src={UsdtIcon} alt="UsdtIcon" />
                                            }
                                            {parseFloat(commit.toFixed(2)).toLocaleString('en')}
                                        </span>
                                    </div>
                                </div>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Balance')}</div>
                                    <div className={style.rightBlock_flex__subTitle}>
                                        {
                                            selectedCoinType == CoinType.Pix
                                                ? <span>
                                                    <img src={coinIcon} alt="coinIcon" />
                                                    {Math.round(predictBalance).toLocaleString('en')}
                                                </span>
                                                : <span>
                                                    <img src={UsdtIcon} alt="coinIcon" />
                                                    {Math.round(balance_usdt).toLocaleString('en')}
                                                </span>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className={style.rightBlock_flex__element}>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Prize pool')}</div>
                                    <div className={style.rightBlock_flex__subTitle}>
                                        <span>
                                            <img src={coinIcon} alt="coinIcon" />
                                            {parseFloat((props.gameProcess.prize_pool ?? 0).toFixed(2)).toLocaleString('en')}
                                        </span>
                                    </div>
                                </div>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Players count')}</div>
                                    <div className={style.rightBlock_flex__subTitle}>
                                        {(props.gameProcess.players_in_game_up ?? 0) == 0 && (props.gameProcess.players_in_game_down ?? 0) == 0
                                            && (props.gameProcess.players_in_game_up_usdt ?? 0) == 0 && (props.gameProcess.players_in_game_down_usdt ?? 0) == 0
                                            ? <span>0</span>
                                            : <div className={style.rightBlock_flex__subTitle_arrows}>
                                                {
                                                    (props.gameProcess.players_in_game_up ?? 0) != 0 || (props.gameProcess.players_in_game_down ?? 0) != 0 &&
                                                    <div>
                                                        <img src={theme == 'light' ? TopArrowIcon : TopArrowDarkIcon} alt="" />
                                                        <span>{props.gameProcess.players_in_game_up ?? 0}</span>
                                                        <img src={theme == 'light' ? DownArrowIcon : DownArrowDarkIcon} alt="" />
                                                        <span>{props.gameProcess.players_in_game_down ?? 0}</span>
                                                        <img src={coinIcon} alt="" />
                                                    </div>
                                                }
                                                {
                                                    (props.gameProcess.players_in_game_up_usdt ?? 0) != 0 || (props.gameProcess.players_in_game_down_usdt ?? 0) != 0 &&
                                                    <div>
                                                        <img src={theme == 'light' ? TopArrowIcon : TopArrowDarkIcon} alt="" />
                                                        <span>{props.gameProcess.players_in_game_up_usdt ?? 0}</span>
                                                        <img src={theme == 'light' ? DownArrowIcon : DownArrowDarkIcon} alt="" />
                                                        <span>{props.gameProcess.players_in_game_down_usdt ?? 0}</span>
                                                        <img src={UsdtIcon} alt="" />
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className={style.rightBlock_flex__element}>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Fee')}</div>
                                    <div className={style.rightBlock_flex__subTitle}>
                                        <span>
                                            <img src={coinIcon} alt="coinIcon" />
                                            {parseFloat(fee.toFixed(2)).toLocaleString('en')} (1%)
                                        </span>
                                    </div>
                                </div>
                            </div> */}

                            <div style={{ marginBottom: 12 }}>
                                <div className={`${style.rightBlock_flex__element} ${style.full}`}>
                                    <div className={style.rightBlock_flex__sub_element}>
                                        <div className={style.rightBlock_flex__title}>{t('Select the currency type for your bet')}</div>
                                    </div>
                                </div>

                                <div className={style.coinTypes}>
                                    {
                                        !(onlyUsdtPredictIds.includes(props.gameProcess.game_id)) &&
                                        <div className={`${style.coinTypes_item} ${selectedCoinType == CoinType.Pix ? style.selected : ""}`} onClick={() => changeCoinType(CoinType.Pix)}>
                                            <img src={coinIcon} />
                                            <span>PIX</span>
                                        </div>
                                    }

                                    <div className={`${style.coinTypes_item} ${selectedCoinType == CoinType.Usdt ? style.selected : ""}`} onClick={() => changeCoinType(CoinType.Usdt)}>
                                        <img src={UsdtIcon} />
                                        <span>USDT</span>
                                    </div>
                                </div>
                            </div>

                            <div className={style.rightBlock_flex__element}>
                                <div className={style.rightBlock_flex__sub_element}>
                                    <div className={style.rightBlock_flex__title}>{t('Bet')}</div>
                                </div>
                            </div>

                            <div className={style.rightBlock_flex__elementInput}>
                                <RoundedNumberInput
                                    value={betPrice}
                                    onChange={changeValue}
                                    color='light'
                                    minValue={0}
                                    maxValue={selectedCoinType == CoinType.Pix ? predictBalance : balance_usdt}
                                    placeholder={"0"}
                                    autoFocus
                                    style={{
                                        padding: "8px 9px",
                                        fontSize: "28px",
                                        borderRadius: "6px",
                                        textAlign: "center",
                                        border: `${isBetPriceNotSelected ? '1px solid #e84266' : ''}`
                                    }}
                                />
                            </div>
                            <div className={style.auxiliary_buttons}>
                                <div onClick={() => setBetPrice(0.01)}>0.01</div>
                                <div onClick={() => setBetPrice(0.05)}>0.05</div>
                                <div onClick={() => setBetPrice(0.10)}>0.10</div>
                                <div onClick={() => setBetPrice(0.25)}>0.25</div>
                                <div onClick={() => setBetPrice(0.50)}>0.50</div>
                                <div onClick={() => setBetPrice(0.75)}>0.75</div>
                            </div>
                        </div>
                    </div>
                    <div className={style.predictionContainer_confirmButtonContainer}>
                        {
                            props.betType == BetType.Up
                                ? <UpButton title={t('Confirm')} subTitle={props.gameProcess.coef_up == null ? null : `${(+props.gameProcess.coef_up).toFixed(2)}x`} disabled={!canBet()} onClick={bet} />
                                : <DownButton title={t('Confirm')} subTitle={props.gameProcess.coef_down == null ? null : `${(+props.gameProcess.coef_down).toFixed(2)}x`} disabled={!canBet()} onClick={bet} />
                        }
                    </div>
                    <div className={style.predictionContainer_footer}>
                        {t('You won`t be able to remove or change your position once you enter it.')}
                    </div>
                </div>

                <div><Toaster /></div>
            </Card>
        </div>

    );
}