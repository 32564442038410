import { useContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import BottomMenu from './Components/BottomMenu';
import coinImage from "./Resources/images/coin.svg";
import GameContext from "./Context/GameContext";
import { useTranslation } from "react-i18next";
import pixie_0 from "./Resources/images/pixie/0.png";
import pixie_1 from "./Resources/images/pixie/1.png";
import pixie_2 from "./Resources/images/pixie/2.png";
import pixie_3 from "./Resources/images/pixie/3.png";
import pixie_4 from "./Resources/images/pixie/4.png";
import pixie_5 from "./Resources/images/pixie/5.png";
import pixie_6 from "./Resources/images/pixie/6.png";
import pixie_7 from "./Resources/images/pixie/7.png";
import pixie_8 from "./Resources/images/pixie/8.png";
import pixie_9 from "./Resources/images/pixie/9.png";
import menu_referrals_dark from './Resources/images/menu/new/referrals_dark.svg';
import menu_referrals_light from './Resources/images/menu/new/referrals_light.svg';
import menu_referrals from './Resources/images/menu/new/referrals.svg';
import { useTheme } from './Context/Predict/themeContext';
import DraggableButton from './Components/Predict/draggableButton/draggableButton';



export default function Layout() {
    const { score, coinsPerClick, coinsPerSecond, userId, firstName, lastName, username, coinsNeedForNewLevel } = useContext(GameContext);
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const { clickerTheme } = useTheme();

    const pixieImages = [
        pixie_0,
        pixie_1,
        pixie_2,
        pixie_3,
        pixie_4,
        pixie_5,
        pixie_6,
        pixie_7,
        pixie_8,
        pixie_9,
    ];

    function parseBigNumber(number: number) {
        if (number >= 10000 && number < 1000000) {
            return `${Math.round(number / 1000)}k`
        } else if (number >= 1000000) {
            return `${(number / 1000000).toFixed(3)}kk`
        } else {
            return `${Math.round(number)}`
        }
    }

    function nicknameFormat(first_name: string | null, last_name: string | null, username: string | null) {
        let nickname;
        if (first_name !== 'None' && first_name !== null) {
            nickname = first_name;
        }
        if (last_name !== 'None' && last_name !== null) {
            if (nickname !== "") {
                nickname = nickname + " " + last_name;
            } else {
                nickname = last_name;
            }
        }

        if (nickname === "") {
            nickname = username;

            if (nickname === "") {
                nickname = "Hidden username";
            }
        }

        return nickname;
    }

    return (
        <div className="app" style={{ paddingTop: 70 }}>
            <div className="game-container_header" >
                <div className="game-container_header-leftSide" >
                    {/*<img src={avatarImage} alt="" />*/}
                    < img src={pixieImages[userId % 10]} alt="avatar" />
                    <span className="game-container_header-leftSide-name" >
                        {/*{firstName !== 'None' ? firstName : ''} {lastName !== 'None' ? lastName : ''} || username}*/}
                        {/*{firstName ? firstName + " " + (lastName === 'None' ? '' : lastName) : username || 'Hidden username'}*/}
                        {nicknameFormat(firstName, lastName, username)}
                    </span>
                </div>
                <div className="game-container_header-rightSide" onClick={() => navigate("/referrals")}>
                    <img src={clickerTheme == null ? menu_referrals : clickerTheme == 'dark' ? menu_referrals_dark : menu_referrals_light} alt="Referrals" />
                    <span>{t("Referrals")}</span>
                </div>
            </div>
            <div className="game-container" >
                <div className="game-container_stats" >
                    <div className="game-container_stats-item" >
                        <span className="game-container_stats-item-name" > {t('Earn per tap')} </span>
                        < span className={`game-container_stats-item-value ${score < 30_000_000 ? 'pulse' : ''}`} >
                            <img src={coinImage} alt="" />
                            {
                                score < 30_000_000
                                    ? <>+10k</>
                                    : <>+{coinsPerClick}</>
                            }
                        </span>
                    </div>
                    < div className="game-container_stats-item" >
                        <span className="game-container_stats-item-name" > {t('Coins to level up')} </span>
                        {/*<span className="game-container_stats-item-name">{t('clicks')}</span>*/}
                        <span className="game-container_stats-item-value" >
                            {
                                coinsNeedForNewLevel > 1000 ?
                                    (coinsNeedForNewLevel > 1000000 ?
                                        `${Math.round(coinsNeedForNewLevel / 1000000)}KK`
                                        :
                                        `${Math.round(coinsNeedForNewLevel / 1000)}K`
                                    )
                                    :
                                    Math.round(coinsNeedForNewLevel)
                            }
                        </span>
                        {/*<span className="game-container_stats-item-value">{event_clicks_counter}</span>*/}
                    </div>
                    <div className="game-container_stats-item" >
                        <span className="game-container_stats-item-name" > {t('Profit per hour')} </span>
                        < span className="game-container_stats-item-value" >
                            <img src={coinImage} alt="" />
                            {parseBigNumber(coinsPerSecond * 60 * 60)}
                        </span>
                    </div>
                </div>
                <span className={`score ${score < 30_000_000 ? 'pulse' : ''}`} style={{ fontSize: score > 9_999_999_999 ? "25px" : "30px" }}>
                    <img src={coinImage} alt="" />
                    {Math.round(score).toLocaleString('en')}
                </span>
            </div>
            < Outlet />
            <BottomMenu />
            {
                location.pathname != `/tap/${userId}` &&
                <DraggableButton onClick={() => navigate(`/tap/${userId}`)} />
            }
        </div>
    );
};