import { Invoice, mapToInvoice } from "./invoice";
import { InvoicePaymentInfo, mapToInvoicePaymentInfo } from "./invoicePaymentInfo";

export class InvoiceProvider {
    public static async create(value: number, token: string): Promise<InvoicePaymentInfo> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/invoice/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token!
            },
            body: JSON.stringify({ deposit_usdttrc20: value })
        })
            .then(response => response.json())
            .then(response => response)

        return mapToInvoicePaymentInfo(response.invoice);
    }

    public static async getList(token: string): Promise<Invoice[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/invoice/list`, {
            method: 'GET',
            headers: {
                'x-api-key': token!
            }
        })
            .then(response => response.json())
            .then(response => response);

        return (response.invoices as any[]).map(mapToInvoice);
    }

    public static async get(id: string, token: string): Promise<InvoicePaymentInfo> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/invoice/${id}`, {
            method: 'GET',
            headers: {
                'x-api-key': token!
            }
        })
            .then(response => response.json())
            .then(response => response);

        return mapToInvoicePaymentInfo(response.invoice);
    }
}