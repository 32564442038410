import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InvoiceProvider } from "../../../../Domain/Invoice/invoiceProvider";
import GameContext from "../../../../Context/GameContext";
import toast, { Toaster } from "react-hot-toast";
import './invoicePaymentModalStyle.scss'
import { RoundedNumberInput } from "../../../../Components/Predict/inputs/roundedTextInput/roundedNumberInput";
import PLoader from "../../../../Components/Predict/loader/Loader";

interface IProps {
    onClose: () => void;
}

export default function InvoicePaymentModal(props: IProps) {
    const { token, userId } = useContext(GameContext);
    const navigate = useNavigate();

    const [value, setValue] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function createDeposit() {
        if (value == null || value <= 0) return toast.error("Specify a valid amount");

        setIsLoading(true);
        const result = await InvoiceProvider.create(value, token!);
        setIsLoading(false);

        navigate(`/predict/profile/invoice/${result.uuid}`);
    }

    return (
        <div className="invoice-payment-modal" style={{ marginTop: 70 }}>
            <Toaster />
            {isLoading && <PLoader />}
            <div className="modal-content">
                <div className="modal-content-header">
                    <h2>Deposit</h2>
                    <div className="close-button" onClick={props.onClose}>✕</div>
                </div>

                <div className="modal-content-input-block">
                    <div className="modal-content-input-block-title">Specify amount</div>
                    <RoundedNumberInput
                        value={value}
                        onChange={setValue}
                        color='light'
                        minValue={5}
                        placeholder={"0"}
                        autoFocus
                        style={{
                            padding: "8px 9px",
                            fontSize: "28px",
                            borderRadius: "6px",
                            textAlign: "center",
                            marginTop: 5,
                        }}
                    />
                    <div className="modal-content-input-block-subtitle">Min amount - 5</div>
                </div>

                <div className="deposit-button" onClick={createDeposit}>
                    Deposit
                </div>
            </div>
        </div>
    )
}
