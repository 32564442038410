import { useEffect } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import EditIcon from '../../../Resources/Predict/icons/edit.svg'
import EditDarkIcon from '../../../Resources/Predict/icons/edit_dark.svg'
import starIcon from '../../../Resources/Predict/icons/star.svg'
import style from './profileStyle.module.scss'
import { Card } from '../../../Components/Predict/card/card'
import { useTheme } from '../../../Context/Predict/themeContext'

enum WalletType {
    MetamaskConnected = 1,
}

export function ProfileLayout() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentLocation = location.pathname;
        if (currentLocation == '/predict/profile' || currentLocation == "/predict/profile/") {
            navigate("/predict/profile/info");
        }
    }, [])

    return (
        <div className={style.profileContainer}>
            <Outlet />
        </div>
    )
}
