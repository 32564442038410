import { mapToClaim } from "../../Claim/clima";
import { maptoPredictLeadBoardInfo, PredictLeadBoardInfo } from "./predictLeadBoardInfo";

export class PredictLeadBoardProvider {
    public static async get(token: string): Promise<{ leadBoardInfos: PredictLeadBoardInfo[], current_user_bets: number | null, to_midnight_hours: number, to_midnight_minutes: number, to_midnight_seconds: number, fund_balance: number }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/leaderboard/list`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return {
            leadBoardInfos: (response.leaderboard_payout as any[]).map(lb => maptoPredictLeadBoardInfo({ ...lb.player, payout: lb.payout })),
            current_user_bets: response.current_user_bets,
            to_midnight_hours: response.to_midnight_hours,
            to_midnight_minutes: response.to_midnight_minutes,
            to_midnight_seconds: response.to_midnight_seconds,
            fund_balance: response.fund_balance
        };
    }

    public static async getUsdt(token: string): Promise<{ leadBoardInfos: PredictLeadBoardInfo[], current_user_bets: number | null, to_midnight_hours: number, to_midnight_minutes: number, to_midnight_seconds: number, fund_balance: number }> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/leaderboard/usdt/list`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return {
            leadBoardInfos: (response.leaderboard_payout as any[]).map(lb => maptoPredictLeadBoardInfo({ ...lb.player, payout: lb.payout })),
            current_user_bets: response.current_user_bets,
            to_midnight_hours: response.to_midnight_hours,
            to_midnight_minutes: response.to_midnight_minutes,
            to_midnight_seconds: response.to_midnight_seconds,
            fund_balance: response.fund_balance
        };
    }

    public static async getClaims(token: string): Promise<any[]> {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/leaderboard/awards/get`, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
        })
            .then(response => response.json())
            .then(response => response)

        return (response.claims as any[]).map(mapToClaim);
    }

    public static async claim(token: string, claimId: number): Promise<any[]> {
        const data = { claim_id: claimId };
        const response = await fetch(`${process.env.REACT_APP_API_URL}/predicts/v1/leaderboard/awards/claim`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'x-api-key': token
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => response)

        return response;
    }
}