import { useContext, useEffect, useState } from 'react';
import style from './preidctLeadBoardStyle.module.scss'
import { PredictLeadBoardInfo } from '../../../../Domain/Predict/leadboard/predictLeadBoardInfo';
import { Claim } from '../../../../Domain/Claim/clima';
import GameContext from '../../../../Context/GameContext';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../Context/Predict/themeContext';
import { PredictLeadBoardProvider } from '../../../../Domain/Predict/leadboard/leadbordProvider';
import menu_predict_dark from '../../../../Resources/images/menu/new/predict_dark.svg';
import menu_predict_light from '../../../../Resources/images/menu/new/predict_light_color.svg';
import CoinIcon from '../../../../Resources/Predict/icons/coin.svg';
import UsdtIcon from '../../../../Resources/Predict/icons/USDT.svg';
import menu_predict from '../../../../Resources/images/menu/new/predict.svg';
import { skins_images } from "../../../../constants";
import default_skin from "../../../../Resources/images/human-free.png";
import СoinBlackWhiteIcon from '../../../../Resources/Predict/icons/coin_black_white.svg';
import PLoader from '../../../../Components/Predict/loader/Loader';

interface IProps {
    coinType: "pix" | "usdt";
    claims: Claim[];
    onClaim: () => void;
}

export default function LeaderBoard(props: IProps) {
    const [predictLeadBoardInfos, setPredictLeadBoardInfos] = useState<PredictLeadBoardInfo[]>([]);
    const [currentUserBets, setCurrentUserBets] = useState<number | null>(null);
    const [fund_balance, setFund_balance] = useState<number | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [userInLeaderBoard, setUserInLeaderBoard] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number | null>(null);

    const { userId, token, skinId, score, updateGame } = useContext(GameContext);

    const { t } = useTranslation();
    const { clickerTheme } = useTheme();

    useEffect(() => {
        loadLeadBoard();
    }, [props.coinType]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft((prevTime) => ((prevTime ?? 0) > 0 ? (prevTime ?? 0) - 1 : 0));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (timeLeft === 0) {
            loadLeadBoard();
        }
    }, [timeLeft]);

    async function loadLeadBoard() {
        setIsLoaded(false);

        const { leadBoardInfos, current_user_bets, to_midnight_hours, to_midnight_minutes, to_midnight_seconds, fund_balance } = props.coinType == 'pix'
            ? await PredictLeadBoardProvider.get(token!) : await PredictLeadBoardProvider.getUsdt(token!);

        setPredictLeadBoardInfos(leadBoardInfos);
        setCurrentUserBets(current_user_bets);
        setFund_balance(fund_balance);

        const totalSeconds = (to_midnight_hours * 3600) + (to_midnight_minutes * 60) + to_midnight_seconds;
        setTimeLeft(totalSeconds);

        const isUserInLeaderBoard = leadBoardInfos.some(item => item.telegram_user_id === userId);
        setUserInLeaderBoard(isUserInLeaderBoard);

        setIsLoaded(true);
    }

    function nicknameFormat(first_name: string | null, last_name: string | null) {
        let nickname = "";

        const firstNameValid = first_name !== 'None' && first_name !== null;
        const lastNameValid = last_name !== 'None' && last_name !== null;

        if (firstNameValid) {
            nickname = first_name;
        }

        if (lastNameValid) {
            if (nickname !== "") {
                nickname = nickname + " " + last_name;
            } else {
                nickname = last_name;
            }
        }

        if (nickname === "") {
            if (nickname === "") {
                nickname = t("Hidden username");
            }
        }

        return nickname;
    }

    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    async function getCoinsFromReferrals(claim: Claim) {
        setIsLoaded(false);
        await PredictLeadBoardProvider.claim(token!, claim.id);
        setIsLoaded(true);
        updateGame({ score: score + (+claim.coins) })
        props.onClaim();
    }

    return (
        <div className={style.leaderboardContainer}>
            {!isLoaded
                ? <PLoader />
                :
                <>
                    <img src={clickerTheme == null ? menu_predict : clickerTheme == 'dark' ? menu_predict_dark : menu_predict_light} alt="" className="leaderboard-header-image" />
                    <h3 className={style.leaderboardContainerName}>{t('Leaderboard by number of bets for today')}</h3>
                    {
                        fund_balance != null && <h3 className={style.leaderboardContainerName}>
                            Total balance {(+((+fund_balance).toFixed(props.coinType == 'pix' ? 2 : 3))).toLocaleString('en')}
                            <img src={props.coinType == 'pix' ? СoinBlackWhiteIcon : UsdtIcon} />
                        </h3>
                    }

                    {
                        timeLeft != null &&
                        <div className={style.leaderboardTimer}>{formatTime(timeLeft)}</div>
                    }
                    {
                        props.claims.map(claim =>
                            <button key={claim.id} className={style.getCoins} onClick={() => getCoinsFromReferrals(claim)}>
                                Claim {claim.coins < 0 || !claim.coins ? 0 : Math.round(claim.coins).toLocaleString('en')}
                                <img src={CoinIcon} />
                            </button>
                        )
                    }

                    <div className={style.leaderboardList}>
                        {
                            predictLeadBoardInfos.map((leaderBoard, i) => (
                                <div key={i} className={`${style.leaderboardListItem} ${leaderBoard.telegram_user_id === userId ? style.current : ''}`}>
                                    <div className={style.leaderboardListItemAvatarContainer}>
                                        <img src={leaderBoard.current_skin_id ? skins_images[leaderBoard.current_skin_id - 1] : default_skin} alt="avatar" className={style.leaderboardListItemAvatar} />
                                    </div>
                                    <div className={style.leaderboardListItemInfo}>
                                        <span className={style.leaderboardListItemInfoName}>{nicknameFormat(leaderBoard.first_name, leaderBoard.last_name)}</span>
                                        <span className={style.leaderboardListItemInfoBalance}>
                                            <img src={clickerTheme == null ? menu_predict : clickerTheme == 'dark' ? menu_predict_dark : menu_predict_light} alt="coin" className={style.leaderboardListItemInfoBalanceCoin} />
                                            {Math.round(leaderBoard.count).toLocaleString('en')}
                                        </span>
                                    </div>
                                    <div className={style.leaderboardListItemRating}>
                                        <div className={style.leaderboardListItemRating_left}>
                                            <img src={props.coinType == 'pix' ? СoinBlackWhiteIcon : UsdtIcon} />
                                            <span>
                                                {(+leaderBoard.payout).toFixed(props.coinType == 'pix' ? 2 : 3)}
                                            </span>
                                        </div>
                                        <div className={style.leaderboardListItemRating_right}>
                                            {i + 1}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            !userInLeaderBoard &&
                            <div className={`${style.leaderboardListItem} ${style.fixed} ${style.current}`}>
                                <div className={style.leaderboardListItemAvatarContainer}>
                                    <img src={skinId ? skins_images[skinId - 1] : default_skin} alt="avatar" className={style.leaderboardListItemAvatar} />
                                </div>
                                <div className={style.leaderboardListItemInfo}>
                                    <span className={style.leaderboardListItemInfoName}>{t("You")}</span>
                                    <span className={style.leaderboardListItemInfoBalance}>
                                        <img src={clickerTheme == null ? menu_predict : clickerTheme == 'dark' ? menu_predict_dark : menu_predict_light} alt="coin" className="leaderboard-list_item-info-balance-coin" />
                                        {Math.round(currentUserBets ?? 0).toLocaleString('en')}
                                    </span>
                                </div>
                                <div className={style.leaderboardListItemRating}>
                                    <div className={style.leaderboardListItemRating_left}>
                                        <img src={props.coinType == 'pix' ? СoinBlackWhiteIcon : UsdtIcon} />
                                        <span>
                                            +{(0).toLocaleString("en")}
                                        </span>
                                    </div>
                                    <div className={style.leaderboardListItemRating_right}>
                                        +50
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    )
}
