export class Claim {
    constructor(
        public coins: number,
        public created_at: Date,
        public id: number,
        public isclaimed: boolean,
        public telegram_user_id: number,
        public updated_at: Date,
        public coin_type: string,
    ) { }
}

export function mapToClaim(data: any) {
    return new Claim(
        data.coins,
        new Date(data.created_at),
        data.id,
        data.isclaimed,
        data.telegram_user_id,
        new Date(data.updated_at),
        data.coin_type
    )
}