import './swapStyle.scss'

interface IProps {
    status: "success" | "error";
    message: string;
    onClose: () => void;
}

export default function SwapNotification(props: IProps) {
    return (
        <div className='swap-notification-overlay'>
            <div className={`swap-notification-container`}>
                <div className='swap-notification-header'>
                    {props.status === 'success' ? 'Success!' : 'Error :c'}
                </div>

                <div className='swap-notification-message'>
                    {props.message}
                </div>

                <div className='swap-notification-button' onClick={props.onClose}>
                    OK
                </div>
            </div>
        </div>
    )
}
